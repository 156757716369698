import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchUserAttributes, signInWithRedirect } from 'aws-amplify/auth';
import { toast } from 'react-toastify';
import SettingsLoader from '../settings-loader';
import { AuthSession } from '../../services/auth-api';
import CognitoAuthSession from '../../services/cognito-auth-api';
import { InternalLoginPage } from '../../routes/pages';
import Login from '../../routes/auth/components/login';
import PasswordReset from '../../routes/auth/components/password-reset';
import ChangePassword from '../../routes/auth/components/change-password';
import NewPassword from '../../routes/auth/components/new-password';
import VerificationCode from '../../routes/auth/components/verification-code';
import { replaceErrorMessage } from '../../services/auth-helpers';
import InactivityCheck from '../inactivity-check';
import { isForgetPassword, unsetForgetPassword } from '../../services/profile-helpers';
import ForgotPassword from '../../routes/auth/components/forgot-password';
import profileApi from '../../services/profile-api';

const MAX_MFA_CHALLENGES = 3;

enum AuthState {
  NONE,
  AUTHENTICATED,
  LOGIN,
  NEW_PASSWORD,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  FORGOT_PASSWORD,
  VERIFICATION,
}

export function AppAuth({ children }: PropsWithChildren) {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const [resetSuccess, setResetSuccess] = useState<boolean | null>();
  const [changeSuccess, setChangeSuccess] = useState<boolean | null>();
  const [authState, setAuthState] = useState<AuthState>(AuthState.NONE);
  const [userAttr, setUserAttr] = useState<any | null>();
  const [authSession, setAuthSession] = useState<AuthSession>();
  const [mfaChallengeCount, setMfaChallengeCount] = useState<number>(0);
  const { route } = useAuthenticator((context: { route: any; }) => [context.route]);

  const handleLogin = async (email: string, password: string) => {
    setBusy(true);
    setError(null);
    try {
      const session = new CognitoAuthSession(email);
      setAuthSession(session);
      const result = await session.signInMFA(password);
      if (result.firstLogin) {
        const phoneNumber = AuthSession.getPhoneNumberFromAttributes(result.userAttr);
        if (phoneNumber) {
          session.setPhoneNumber(phoneNumber);
        }
        setUserAttr(result.userAttr);
        setAuthState(AuthState.NEW_PASSWORD);
      } else if (result.mfaRequired) {
        session.setPhoneNumber(result.mfaChallengeParams.CODE_DELIVERY_DESTINATION);
        setAuthState(AuthState.VERIFICATION);
      } else {
        const isAuthenticated = await session.isAuthenticated();
        if (isAuthenticated) {
          setAuthState(AuthState.AUTHENTICATED);
        } else {
          throw new Error('Not authenticated');
        }
      }
    } catch (err: any) {
      setError(replaceErrorMessage(err?.message ?? 'Login error'));
    } finally {
      setBusy(false);
    }
  };

  const handlePasswordReset = async (email: string) => {
    setBusy(true);
    setResetSuccess(null);
    setError(null);
    try {
      const session = new AuthSession(email);
      setAuthSession(session);
      await session.sendVerificationCode();
      setAuthState(AuthState.UPDATE_PASSWORD);
    } catch (err: any) {
      setError(replaceErrorMessage(err?.message ?? 'Reset password error'));
    } finally {
      setBusy(false);
    }
  };

  const handleChangePassword = async (verficationCode: string, password: string) => {
    setBusy(true);
    setChangeSuccess(false);
    setError(null);
    unsetForgetPassword();
    try {
      if (authSession) {
        await authSession.updatePassword(verficationCode, password);
        setChangeSuccess(true);
      } else {
        throw new Error('No session found');
      }
    } catch (err: any) {
      setError(replaceErrorMessage(err?.message ?? 'Change password error'));
    } finally {
      setBusy(false);
    }
  };

  const handleForgotAndChangePassword = async (email: string, verficationCode: string, password: string) => {
    setBusy(true);
    setChangeSuccess(false);
    setError(null);
    unsetForgetPassword();
    try {
      const session = new AuthSession(email);
      setAuthSession(session);
      await session.updatePassword(verficationCode, password);
      setChangeSuccess(true);
    } catch (err: any) {
      setError(replaceErrorMessage(err?.message ?? 'Forgot password error'));
    } finally {
      setBusy(false);
    }
  };

  const handleNewPassword = async (password: string, phoneNumber: string) => {
    setBusy(true);
    setChangeSuccess(false);
    setError(null);
    try {
      if (authSession && userAttr) {
        const existingPhoneNumber = AuthSession.getPhoneNumberFromAttributes(userAttr);
        const result = await authSession.newPassword(password, existingPhoneNumber ? {} : { phone_number: phoneNumber });
        if (result.mfaRequired) {
          authSession.setPhoneNumber(result.mfaChallengeParams.CODE_DELIVERY_DESTINATION);
          setAuthState(AuthState.VERIFICATION);
        }
        setChangeSuccess(true);
      } else {
        throw new Error('No username found');
      }
    } catch (err: any) {
      setError(replaceErrorMessage(err?.message ?? 'Change password error'));
    } finally {
      setBusy(false);
    }
  };

  const handleVerificationCode = async (code: string, rememberMe: boolean) => {
    setBusy(true);
    setError(null);
    try {
      if (authSession) {
        if (mfaChallengeCount > MAX_MFA_CHALLENGES) {
          throw new Error('Max attempts reached');
        }
        await authSession.sendMFACode(code);
        if (rememberMe) {
          await authSession.setDeviceStatusRemembered();
          try {
            const devices = await AuthSession.fetchDevices();
            if (devices.length > 1) {
              devices.filter((device) => authSession.getUser().deviceKey !== device.id).forEach(async (device) => {
                await AuthSession.deleteDevice(device.id);
              });
            }
          } catch {
            throw new Error('Error removing devices');
          }
        } else {
          await authSession.setDeviceStatusNotRemembered();
        }
        const isAuthenticated = await authSession.isAuthenticated();
        if (isAuthenticated) {
          setMfaChallengeCount(0);
          const attr = await fetchUserAttributes();
          if (attr.phone_number_verified === 'false') {
            await profileApi.setUserAttributes({ phone_number_verified: 'true' });
          }
          setAuthState(AuthState.AUTHENTICATED);
        } else {
          throw new Error('Not authenticated');
        }
      } else {
        throw new Error('No session found');
      }
    } catch (err: any) {
      setMfaChallengeCount((currentCount) => currentCount + 1);
      setError(err?.message ?? 'Verification code error');
    } finally {
      setBusy(false);
    }
  };

  const handleResendVerificationCode = async () => {
    if (authSession && authSession.getPassword()) {
      const password = authSession.getPassword();
      handleLogin(authSession.getUserName(), password!);
      toast(`Code resent to ${authSession.getPhoneNumber()}`, {
        position: 'bottom-left',
        type: 'success',
      });
    }
  };

  const handleFederatedLogin = async () => {
    await signInWithRedirect({ provider: { custom: 'AzureAD' } });
  };

  const resetAuth = async () => {
    unsetForgetPassword();
    setAuthState(AuthState.LOGIN);
    setChangeSuccess(null);
    setResetSuccess(null);
    setUserAttr(null);
    setError(null);
  };

  const handleForgotPassword = () => {
    resetAuth();
    setAuthState(AuthState.RESET_PASSWORD);
  };

  const checkValidUser = async () => {
    if (route !== 'authenticated' && route !== 'idle') {
      const urlParams = new URLSearchParams(window.location.search);
      const codeExists = urlParams.get('code');
      if (window.location.pathname === InternalLoginPage.path) {
        handleFederatedLogin();
      } else if (isForgetPassword()) {
        setAuthState(AuthState.FORGOT_PASSWORD);
      } else if (route === 'setup' && codeExists) {
        window.location.href = '/internal';
      } else {
        setAuthState(AuthState.LOGIN);
      }
    }

    if (await AuthSession.isCurrentUserAuthenticated()) {
      setAuthState(AuthState.AUTHENTICATED);
      if (window.location.pathname === InternalLoginPage.path) {
        window.location.href = '/';
      }
    }
  };

  const renderAuthState = () => {
    switch (authState) {
      case AuthState.LOGIN: return (
        <Login
          onLogin={handleLogin}
          onForgotPassword={handleForgotPassword}
          loading={busy}
          error={error}
        />
      );
      case AuthState.NEW_PASSWORD: return (
        <NewPassword
          onNewPassword={handleNewPassword}
          onCancel={resetAuth}
          phoneNumber={authSession?.getPhoneNumber()}
          loading={busy}
          error={error}
          success={changeSuccess}
        />
      );
      case AuthState.RESET_PASSWORD: return (
        <PasswordReset
          onPasswordReset={handlePasswordReset}
          onCancel={resetAuth}
          loading={busy}
          error={error}
          success={resetSuccess}
        />
      );
      case AuthState.UPDATE_PASSWORD: return (
        <ChangePassword
          onChangePassword={handleChangePassword}
          onCancel={resetAuth}
          loading={busy}
          error={error}
          success={changeSuccess}
        />
      );
      case AuthState.FORGOT_PASSWORD: return (
        <ForgotPassword
          onForgotPassword={handleForgotAndChangePassword}
          onCancel={resetAuth}
          loading={busy}
          error={error}
          success={changeSuccess}
        />
      );
      case AuthState.VERIFICATION: return (
        <VerificationCode
          phoneNumber={authSession?.getPhoneNumber() ?? ''}
          onVerificationCode={handleVerificationCode}
          onResendCode={handleResendVerificationCode}
          onCancel={resetAuth}
          loading={busy}
          error={error}
          success={changeSuccess}
        />
      );
      case AuthState.AUTHENTICATED: return (
        <>
          <InactivityCheck />
          <SettingsLoader />
          {children}
        </>
      );
      default: return (<span />);
    }
  };

  useEffect(() => {
    checkValidUser();
  }, [route]);

  return renderAuthState();
}

export default AppAuth;
