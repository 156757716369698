import React, { useCallback, useEffect } from 'react';
import {
  CardContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { isNil } from 'lodash';
import { ProductCode } from '../../../../services/models/product-code';
import { EditableDecision, ProductQuoteDecisonTable } from '../decision-table';
import EditableCommissionDetails from './editable-commission-details';
import { useAppSelector } from '../../../../store/hooks';
import { selectApplication, selectProductQuoteDecision } from '../../../../features/application-slice';
import { ProductDto } from '../../../../services/models/product-dto';
import IncomeProtectionForm from '../income-protection-form';
import RealLifeForm from '../real-life-form';
import HealthCareForm from '../health-care-form';
import { ProductEditability, ProductFormProps, ProductFormValues } from '../../../../services/product-helpers';
import { HintCardContent } from '../../../../components/card-hint';
import { QuoteBasis } from '../../../../services/models/quote-basis';
import { Premium } from '../../../../services/models/premium';
import { FormErrors } from '../../../../components/form';
import ProductQuoteDecisionAccordion from '../decision-table/product-quote-decision-accordion';
import UnderwritingNotes from './underwriting-notes';
import ProductQuoteKeyDecisonTable from '../decision-table/product-quote-key-decision-table';
import { hasApplicantReasonDecisions } from '../../../../services/decision-helpers';
import { CommissionDetails } from '../../../../components/commission-details';

function getOnboardingFormContent(code: ProductCode, props: ProductFormProps) {
  switch (code) {
    case ProductCode.INCOME_PROTECTION: return <IncomeProtectionForm {...props} />;
    case ProductCode.REAL_LIFE: return (<RealLifeForm {...props} />);
    case ProductCode.HEALTH_CARE: return <HealthCareForm {...props} />;
    default: return null;
  }
}

function getPremiumFromDecison(premium: Premium | null | undefined): number | null {
  return premium?.permanentPremium?.min ?? premium?.temporaryPremium?.min ?? null;
}

export interface OnboardingProductFormProps {
  applicantId: string;
  product: ProductDto;
  errors: string[];
  displayHint: boolean;
  onProductChange: (changes: Partial<ProductFormValues>) => unknown;
  editable?: ProductEditability;
}

function OnboardingProductForm({
  applicantId,
  product,
  errors,
  displayHint,
  onProductChange,
  editable = ProductEditability.Editable,
}: OnboardingProductFormProps) {
  const { t } = useTranslation();
  const { getValues, resetField, setValue } = useFormContext();
  const productQuoteDecision = useAppSelector(selectProductQuoteDecision(product.id));
  const { agency } = useAppSelector(selectApplication);

  const onChangeCommitted = useCallback((name: string, value: unknown) => {
    const changes = { [name]: value };
    if (name === 'quoteBasis') {
      if (value === QuoteBasis.CoverAmount) {
        const coverAmountFromDecision = productQuoteDecision?.coverAmount;
        if (coverAmountFromDecision) {
          resetField('coverAmount', { defaultValue: coverAmountFromDecision });
          setValue('coverAmount', coverAmountFromDecision);
        }
        changes.coverAmount = getValues().coverAmount;
      }
      if (value === QuoteBasis.Premium) {
        const premiumFromDecision = getPremiumFromDecison(productQuoteDecision?.premiums);
        if (premiumFromDecision) {
          resetField('premium', { defaultValue: premiumFromDecision });
          setValue('premium', premiumFromDecision);
        }
        changes.premium = getValues().premium;
      }
    }
    onProductChange(changes);
  }, [onProductChange]);

  useEffect(() => {
    if (product.quoteBasis === QuoteBasis.Premium && productQuoteDecision?.premiums) {
      const premiumFromDecision = getPremiumFromDecison(productQuoteDecision.premiums);
      if (premiumFromDecision) {
        resetField('premium', { defaultValue: premiumFromDecision });
        setValue('premium', premiumFromDecision);
      }
    }
  }, [productQuoteDecision?.premiums]);

  useEffect(() => {
    if (product.coverAmount) {
      resetField('coverAmount', { defaultValue: product.coverAmount });
      setValue('coverAmount', product.coverAmount);
    }
  }, [product.coverAmount]);

  return (
    <>
      {!productQuoteDecision && displayHint && (
        <HintCardContent>
          {t('components.onboardingProductForm.configure')}
        </HintCardContent>
      )}
      <CardContent>
        {getOnboardingFormContent(product.code!, {
          onChangeCommitted,
          decisionCoverAmount: productQuoteDecision?.coverAmount,
          restrictions: productQuoteDecision?.restrictions,
          editable,
        })}
        <FormErrors errors={errors} sx={{ marginTop: '16px' }} />
      </CardContent>
      <EditableDecision
        decision={productQuoteDecision}
        onChangeCommitted={onChangeCommitted}
        readOnly={editable !== ProductEditability.Editable || !isNil(productQuoteDecision?.restrictions?.coverAmount)}
      />
      {productQuoteDecision && <UnderwritingNotes productDecision={productQuoteDecision} />}
      {productQuoteDecision && (
        <ProductQuoteKeyDecisonTable
          applicantId={applicantId}
          decision={productQuoteDecision}
        />
      )}
      {productQuoteDecision && hasApplicantReasonDecisions(applicantId, productQuoteDecision) && (
        <ProductQuoteDecisionAccordion productId={product.id}>
          <ProductQuoteDecisonTable
            applicantId={applicantId}
            decision={productQuoteDecision}
          />
        </ProductQuoteDecisionAccordion>
      )}
      {editable !== ProductEditability.Editable
        ? (
          <CommissionDetails
            productId={product.id}
            commission={productQuoteDecision?.commission}
            agency={agency}
          />
        )
        : (
          <EditableCommissionDetails
            productId={product.id}
            commission={productQuoteDecision?.commission}
            onChangeCommitted={onChangeCommitted}
          />
        )}
    </>
  );
}

export default OnboardingProductForm;
