import * as yup from 'yup';
import { FieldErrors } from 'react-hook-form';
import { isNil } from 'lodash';
import { ProductDto } from './models/product-dto';
import { ProductType } from './models/product-type';
import { CoverType } from './models/cover-type';
import { handleError } from './http';
import { ProductDefinition } from './models/product-definition';
import { ApplicantDto } from './models/applicant-dto';
import { asAge } from '../utils/converters';
import { SalesResource } from './models/sales-resource';
import { QuoteBasis } from './models/quote-basis';
import { Commission } from './models/commission';
import { ProductRestrictions } from './models/product-restrictions';
import { DeferredPeriodInWeeks } from './models/deferred-period-in-weeks';
import { PremiumStyle } from './models/premium-style';
import { booleanToYesNo, stringToNumericEnum, yesNoToBoolean } from '../components/form/form.utils';
import { ProductWithoutIdDto } from './models/product-without-id-dto';
import { ClaimPeriod } from './models/claim-period';
import { ActivatedProductDto } from './bff/models/activated-product-dto';

export function getProductsForApplicant(products: ProductDto[], applicantId: string): ProductDto[] {
  return products.filter((p) => p.applicantIds?.includes(applicantId));
}

export enum DecisionType {
  STANDARD = 'STANDARD',
  NON_STANDARD = 'NON_STANDARD',
  REFER = 'REFER',
  EVIDENCE_REQUIRED = 'EVIDENCE_REQUIRED',
  POSTPONE = 'POSTPONE',
  DECLINE = 'DECLINE',
}

export enum ProductErrorType {
  Applicant = 'Applicant',
  Product = 'Product',
}

export type ProductError = {
  field: string,
  id: string,
  message: string,
  type: ProductErrorType,
};

export interface ProductFormRenderProps {
  isValid: boolean
  resolving: boolean
  empty: boolean
  errors: FieldErrors<ProductDto>
}

export enum ProductEditability {
  Editable = 'editable',
  CoverAmountOnly = 'coverAmountOnly',
  ReadOnly = 'readOnly',
}

export interface ProductFormProps {
  onChangeCommitted: (name: string, value: unknown) => unknown;
  decisionCoverAmount?: number;
  restrictions?: ProductRestrictions | null;
  editable?: ProductEditability;
}

const commissionValidationSchema = yup.object<Commission>({
  style: yup.string().required('Commission style is required'),
  initialSacrificePercentage: yup.number()
    .required('Initial sacrifice percentage is required')
    .min(0, 'Initial sacrifice must be greater than or equal to 0')
    .max(100, 'Initial sacrifice must be less than or equal to 100'),
  renewalSacrificePercentage: yup.number()
    .required('Renewal sacrifice percentage is required')
    .min(0, 'Renewal sacrifice must be greater than or equal to 0')
    .max(100, 'Renewal sacrifice must be less than or equal to 100'),
});

export const realLifeValidationSchema = yup.object<ProductDto>({
  coverType: yup.string().required('Cover type is required'),
  quoteBasis: yup.string().required('Quote basis is required'),
  coverAmount: yup.number().when('quoteBasis', ([quoteBasis]) => (
    quoteBasis === QuoteBasis.CoverAmount
      ? yup.number().required('Sum assured is required')
      : yup.number().nullable())),
  premium: yup.number().when('quoteBasis', ([quoteBasis]) => (
    quoteBasis === QuoteBasis.Premium
      ? yup.number().required('Premium is required')
      : yup.number().nullable())),
  termOrToAge: yup
    .number()
    .test('toAge', 'Term or age is required', (_, context) => {
      const { toAge, term } = context.parent;
      return !!term || !!toAge;
    }),
  commission: commissionValidationSchema,
});

export const incomeProtectionPreSalesValidationSchema = yup.object<ProductDto>({
  coverAmount: yup.number().required('Monthly benefit is required'),
  toAge: yup.number().required('Until age is required'),
});

export const incomeProtectionValidationSchema = yup.object({
  quoteBasis: yup.string().required('Quote basis is required'),
  coverAmount: yup.number().when('quoteBasis', ([quoteBasis]) => (
    quoteBasis === QuoteBasis.CoverAmount
      ? yup.number().required('Monthly benefit is required')
      : yup.number().nullable())),
  premium: yup.number().when('quoteBasis', ([quoteBasis]) => (
    quoteBasis === QuoteBasis.Premium
      ? yup.number().required('Premium is required')
      : yup.number().nullable())),
  premiumStyle: yup.string().required('Premium option is required'),
  claimPeriod: yup.string().required('Claim period is required'),
  coverType: yup.string().required('Indexation is required'),
  deferredPeriod: yup.string().required('Waiting period is required'),
  termOrToAge: yup
    .number()
    .test('toAge', 'Term or age is required', (_, context) => {
      const { toAge, term } = context.parent;
      return !!term || !!toAge;
    }),
  commission: commissionValidationSchema,
});

export const healthCareValidationSchema = yup.object<ProductDto>({
  mentalHealthCover: yup.string().required('Required'),
});

function getValidationSchemaFor(productType: ProductType) {
  switch (productType) {
    case ProductType.Ip: return incomeProtectionValidationSchema;
    case ProductType.ImpairedLife: return realLifeValidationSchema;
    case ProductType.HealthCare: return healthCareValidationSchema;
    default: throw Error(`Unexpected product type ${productType}`);
  }
}

function getPreSalesValidationSchemaFor(productType: ProductType) {
  switch (productType) {
    case ProductType.Ip: return incomeProtectionPreSalesValidationSchema;
    case ProductType.ImpairedLife: return realLifeValidationSchema;
    case ProductType.HealthCare: return healthCareValidationSchema;
    default: throw Error(`Unexpected product type ${productType}`);
  }
}

export function getValidationSchema(isPreSales: boolean, productType: ProductType) {
  return isPreSales ? getPreSalesValidationSchemaFor(productType) : getValidationSchemaFor(productType);
}

export function isValid(isPreSales: boolean, product: ProductDto): boolean {
  const schema = getValidationSchema(isPreSales, product.productType!);
  return schema.isValidSync(product);
}

export const RealLifeCoverTypes = {
  Decreasing: CoverType.Decreasing,
  Level: CoverType.Level,
  Increasing: CoverType.Increasing,
};

export const IpPreSalesDefaults: Partial<ProductWithoutIdDto> = {
  coverType: CoverType.Level,
  premiumStyle: PremiumStyle.LevelGuaranteed,
  claimPeriod: ClaimPeriod.Full,
};

export function toProductErrors(e: unknown, errorProps?: Partial<ProductError>): ProductError[] {
  const error = handleError(e);
  if (error.errors) {
    return error.errors as ProductError[];
  }
  if (error) {
    return [
      {
        id: '',
        type: ProductErrorType.Product,
        field: '',
        message: error.toString(),
        ...errorProps,
      },
    ];
  }
  return [];
}

export const getErrorsByProductId = (
  productId: string,
  errors: ProductError[],
): ProductError[] => errors.filter((error) => error.type === ProductErrorType.Product && error.id === productId);

export const getErrorsApplicantId = (
  applicantId: string,
  errors: ProductError[],
): ProductError[] => errors.filter((error) => error.type === ProductErrorType.Applicant && error.id === applicantId);

export const getErrorsByProductDefinition = (
  productDefinition: ProductDefinition | null,
  errors: ProductError[],
): ProductError[] => {
  if (!productDefinition) return [];
  return errors.filter((error) => error.type === ProductErrorType.Product && error.id === productDefinition?.name) ?? [];
};

export const getProductDefinitionByCode = (
  code: string,
  productDefinitions: ProductDefinition[],
): ProductDefinition | null => productDefinitions.find((productDefinition) => productDefinition.productCode === code) ?? null;

export const listProductErrors = (errors: ProductError[]): string[] => errors.map((error) => error.message);

export const mergeProductFormErrors = (productErrors: ProductError[], errors?: FieldErrors | null): string[] => {
  if (errors) {
    return [
      ...listProductErrors(productErrors),
      ...Object.keys(errors).reduce((acc: string[], error) => {
        if (errors[error] && errors[error]!.message) {
          return [...acc, errors[error]!.message as string];
        }
        return acc;
      }, []),
    ];
  }
  return [...listProductErrors(productErrors)];
};

function validateApplicantForProduct(applicant: ApplicantDto, definition: ProductDefinition): string | null {
  const age = applicant.age || asAge(applicant.dateOfBirth);
  const maxAgeAtEntry = definition.limits?.maxAgeAtEntry;
  return (age !== null && maxAgeAtEntry && maxAgeAtEntry < age)
    ? `The maximum age at start for ${definition.name} is ${maxAgeAtEntry} years old` : null;
}

export function validateApplicantsForProduct(applicants: ApplicantDto[], definition: ProductDefinition): string | null {
  return applicants.map((a) => validateApplicantForProduct(a, definition)).filter((message) => message !== null)[0] || null;
}

export function getProductSalesResourceByName(name: string, productDefinition: ProductDefinition | null): SalesResource | null {
  if (productDefinition) {
    return productDefinition.salesResources?.find((salesResource) => salesResource.name === name) ?? null;
  }
  return null;
}

export function getProductToolkit(code: string, products: ProductDto[], productDefinitions: ProductDefinition[]): SalesResource | null {
  if (products.some((product) => product.code === code)) {
    return getProductSalesResourceByName('Toolkit', getProductDefinitionByCode(code, productDefinitions));
  }
  return null;
}

export interface ProductFormValues extends Omit<ProductDto, 'mentalHealthCover' | 'deferredPeriod'> {
  mentalHealthCover: string | null;
  deferredPeriod: string | null;
}

export function toFormValues({ mentalHealthCover, deferredPeriod, ...values }: ProductDto): ProductFormValues {
  return {
    ...values,
    mentalHealthCover: booleanToYesNo(mentalHealthCover),
    deferredPeriod: deferredPeriod?.toString() || null,
  };
}

export function toApiValue(name: keyof ProductDto, value: ProductDto[typeof name]) {
  switch (name) {
    case 'deferredPeriod': return stringToNumericEnum<DeferredPeriodInWeeks>(value as string);
    case 'mentalHealthCover': return yesNoToBoolean(value as 'yes' | 'no' | null);
    default: return value;
  }
}

const disallowedDeferredPeriodsForLevelGuaranteedPremiumStyle: DeferredPeriodInWeeks[] = [DeferredPeriodInWeeks.NUMBER_0, DeferredPeriodInWeeks.NUMBER_1];

export function getDisallowedPremiumStylesFor(deferredPeriod: DeferredPeriodInWeeks | null | undefined): PremiumStyle[] {
  return !isNil(deferredPeriod) && disallowedDeferredPeriodsForLevelGuaranteedPremiumStyle.includes(deferredPeriod) ? [PremiumStyle.LevelGuaranteed] : [];
}

export function getDisallowedDeferredPeriodsFor(premiumStyle: PremiumStyle | null | undefined): DeferredPeriodInWeeks[] {
  return premiumStyle === PremiumStyle.LevelGuaranteed ? disallowedDeferredPeriodsForLevelGuaranteedPremiumStyle : [];
}

export function productsHasPolicyReference(products: ProductDto[]): boolean {
  return products.some((product) => !!product.policyReference);
}

export function activatedProductsHasPolicyReference(products: ActivatedProductDto[]): boolean {
  return products.some((product) => !!product.policyRef);
}

export default {};
