import { useState, useCallback, useRef } from 'react';
import useBusyState from './use-busy-state';

export const usePollingService = <T>(
  fetchData: (params: any) => Promise<T>,
  intervalMs: number = 1000,
  timeoutMs: number = 20000,
) => {
  const initialised = useRef<boolean>(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [complete, setComplete] = useState<boolean>(false);
  const [polling, withBusyState] = useBusyState();

  const poll = useCallback(async (params: Record<string, any>) => {
    let isCancelled = false;
    const startTime = Date.now();
    setData(null);
    setError(null);
    setComplete(false);

    const makeRequest = withBusyState(async () => {
      try {
        const response: T = await fetchData(...(Object.values(params) as [Record<string, any>]));
        if (!isCancelled) {
          setData(response);
        }
      } catch (err: any) {
        if (err?.response?.status === 404) {
          if (Date.now() - startTime < timeoutMs) {
            setTimeout(makeRequest, intervalMs);
          } else if (!isCancelled) {
            setComplete(true);
          }
        } else {
          setError(err?.message);
        }
      }
    });

    if (!initialised.current) {
      initialised.current = true;
      makeRequest();
    }

    return () => {
      isCancelled = true;
    };
  }, [fetchData, intervalMs, timeoutMs]);

  return {
    data,
    error,
    polling,
    complete,
    poll,
  };
};

export default usePollingService;
