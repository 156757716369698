import React from 'react';
import { Stack, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { ApplicationDto } from '../../../../services/models/application-dto';
import { QuoteDecision } from '../../../../services/models/quote-decision';
import { TotalPrice } from '../price';
import { Guidance } from './both-clients-products.styles';
import ProductDecisionCard from './product-decision-card';
import { getApplicantName } from '../../../../services/application-helpers';
import { getProductDecision } from '../../../../services/decision-helpers';
import { getProductsForApplicant } from '../../../../services/product-helpers';
import ApplicationExpiry from '../application-expiry';

export interface BothClientsPanelProps {
  application: ApplicationDto;
  quoteDecision: QuoteDecision | null;
  applicationExpiry?: string | null;
}

function BothClientsProducts({ application, quoteDecision, applicationExpiry = undefined }: BothClientsPanelProps) {
  const { t } = useTranslation();

  return (
    <Stack gap={4}>
      <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
        <Guidance>
          <Typography variant="h3">
            <ErrorOutlineIcon color="success" />
            {t('components.bothClientsPanel.guidance1')}
          </Typography>
          <Typography variant="h3">
            {t('components.bothClientsPanel.guidance2')}
          </Typography>
        </Guidance>
        <Stack alignItems="flex-end">
          {quoteDecision?.plan.totalPremium && (
            <Typography variant="body2" component="span">
              {t('components.bothClientsPanel.allCoverTotal')}
              <TotalPrice
                premium={quoteDecision.plan.totalPremium}
                display="inline-flex"
                priceProps={{ size: 'large' }}
              />

            </Typography>
          )}
          <ApplicationExpiry expiryDate={applicationExpiry} status={application.status} />
        </Stack>
      </Stack>
      <Stack direction="row" gap={(theme) => theme.mixins.mainHorizontalSpacing}>
        {application.applicants.map((applicant) => (
          <Stack key={applicant.id} flexGrow={1} flexBasis={1} gap={3}>
            <Typography variant="h6">{getApplicantName(applicant)}</Typography>
            {getProductsForApplicant(application.products, applicant.id).map((product) => (
              <ProductDecisionCard
                key={product.id}
                applicantId={applicant.id}
                product={product}
                decision={getProductDecision(quoteDecision, product.id)}
                agency={application.agency}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default BothClientsProducts;
