import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Layout, MainContent } from '../../components/layout';
import ApplicationHeader from '../application/components/application-layout/application-header';
import { useAppSelector } from '../../store/hooks';
import { selectAvailableApplication } from '../../features/application-slice';
import { SearchPage } from '../pages';
import { activatedProductsHasPolicyReference, getProductsForApplicant } from '../../services/product-helpers';
import { getProductDecision, isImmediateDecision } from '../../services/decision-helpers';
import ProductCompleted from './components/product-completed';
import FixedBenefit from './components/fixed-benefit';
import { ProductCode } from '../../services/models/product-code';
import applicationApi from '../../services/application-api';
import { toastError } from '../../components/toast';
import { ActivatedProductDto } from '../../services/bff/models/activated-product-dto';
import { getActivatedProductPolicyRef, getApplicantName } from '../../services/application-helpers';
import ApplicationDocuments from './components/application-documents';

function Complete() {
  const { t } = useTranslation();
  const { application, quoteDecision } = useAppSelector(selectAvailableApplication);
  const [activatedProducts, setActivatedProducts] = useState<ActivatedProductDto[] | null>();
  const products = application.products.filter((p) => isImmediateDecision(getProductDecision(quoteDecision, p.id)?.decision));
  const hasIpProduct = products.some((product) => product.code === ProductCode.INCOME_PROTECTION);
  const navigate = useNavigate();
  const hasPolicyReference = activatedProducts ? activatedProductsHasPolicyReference(activatedProducts) : false;

  const handleBackClick = () => {
    navigate(SearchPage.path);
  };

  const init = useCallback(async () => {
    try {
      const response = await applicationApi.getApplicationStatus(application.id, true);
      setActivatedProducts(response.application.activatedProducts ?? []);
    } catch {
      toastError(t('components.complete.statusError'));
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout
      header={<ApplicationHeader />}
    >
      <MainContent maxWidth={false} sx={{ flexDirection: 'column', gap: 2 }}>
        <Stack gap={1} sx={{ mb: 4 }}>
          <Typography variant="h2">{t('components.complete.title')}</Typography>
          <Typography variant="h3" sx={(theme) => ({ fontFamily: theme.typography.h6.fontFamily, mt: -0.5 })}>{t('components.complete.subTitle')}</Typography>
        </Stack>
        <Stack gap={4} alignItems="flex-start">
          <Stack gap={4} direction="row">
            {application.applicants.flatMap((applicant) => (
              <Stack gap={2} key={applicant.id}>
                <Typography variant="h2" sx={(theme) => ({ fontFamily: theme.typography.h6.fontFamily, fontSize: '26px' })}>{getApplicantName(applicant)}</Typography>
                {getProductsForApplicant(products, applicant.id).map((product) => (
                  <ProductCompleted
                    key={`${applicant.id}-${product.id}`}
                    applicant={applicant}
                    product={product}
                    policyRef={activatedProducts && getActivatedProductPolicyRef(product.id, activatedProducts)}
                    sx={{ minWidth: 450 }}
                  />
                ))}
              </Stack>
            ))}
          </Stack>
          {hasPolicyReference && (
            <Stack gap={1} alignItems="flex-start">
              <Typography variant="h3">{t('components.complete.documents')}</Typography>
              <ApplicationDocuments application={application} />
            </Stack>
          )}
          {hasIpProduct && <FixedBenefit />}
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={handleBackClick}
          >
            {t('components.complete.backToDashboard')}
          </Button>
        </Stack>
      </MainContent>
    </Layout>
  );
}

export default Complete;
